/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

window.Vue = require("vue").default; //ComputerSoft [29.02.2024] - changed "window.Vue = require("vue");" to "window.Vue = require("vue").default;"

window.Vuex = require("vuex");

import store from "./store";

require("./bootstrap");

Event = new Vue();
flash = function (message, level = "success") {
  Event.$emit("flash", { message, level });
};
/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin disable components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

import Lazy from "./directives/lazy.js";

Vue.directive("lazy", Lazy);

Vue.component("cookie-info", require("./components/CookieInfo.vue").default);
Vue.component("mini-cart", require("./components/Cart/MiniCart").default);
Vue.component("product-image", require("./components/Product/ProductImage").default);
Vue.component(
  "page-check-zip-code-form",
  require("./components/Page/PageCheckZipCodeForm").default
);
Vue.component(
  "delivery-checker",
  require("./components/DeliveryChecker/DeliveryChecker").default
);
Vue.component("drop-down-menu", require("./components/DropDownMenu").default);
Vue.component(
  "product-availability-first-date",
  require("./components/Product/ProductAvailabilityFirstDate").default
);
Vue.component("reviews", require("./components/Reviews/Reviews").default);
Vue.component("alert", require("./components/Alerts/Alert").default);

new Vue({
  el: "#app",
  store,

  data() {
    return {
      publishedAt: "",
    };
  },

  created() {
    this.$store.dispatch("cart/fetchProducts");
  },

  methods: {
    clear() {
      this.$root.$emit("dropdown-opened", null);
    },
  },
});
