<template>
  <div :class="`alert ${alertType}`" role="alert" v-if="show">
    <slot></slot>
    <button @click="show = false">&times;</button>
  </div>
</template>

<script>
export default {
  props: ["type"],

  data() {
    return {
      show: true,
    };
  },

  computed: {
    alertType() {
      switch (this.type) {
        case "danger":
          return "alert-danger";
        default:
          return "alert-success";
      }
    },
  },

  methods: {},
};
</script>

<style lang="scss" scoped>
@import "../../../sass/_variables.scss";

.alert {
  position: fixed;
  top: 50px;
  left: 50%;
  transform: translateX(-50%);
  min-width: 450px;
  max-width: 600px;
  z-index: 10000;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.15);
}

button {
  position: absolute;
  top: 12px;
  right: 12px;
  font-size: 20px;
  border: none;
  color: #fff;
  background-color: transparent;
  cursor: pointer;
}
</style>
