
// initial state
import FolderTree from "../../components/Admin/FolderTree";

const state = {
  files: [],
  folders: {},
  editFile: null
};

// getters
const getters = {};

// actions
const actions = {
  fetchFolders({commit}) {
    axios
      .get('/api/folder-tree')
      .then(response => {
        let folders = [];
        let ft = new FolderTree();
        for (let property in response.data) {
          if (response.data.hasOwnProperty(property)) {
            folders.push(ft.parse(response.data[property]));
          }
        }
        commit('setFolders', folders);
      })
  },

  getFiles({commit}, folderId) {
    let response = axios.get(`/api/folder/${folderId}/files`);
    response.then(response => {
      // this.files = response.data;
      commit('setFiles', response.data);
    });

    return response;
  },

  editFile({commit}, file) {
    commit('setEditFile', file);
  },

  deleteFile({commit, dispatch}, file) {
    let response = axios
      .post('/api/file/delete', {id: file.id});
      response.then(response => {

        if (response.data.status === "success") {
          Event.$emit('showAlert', {alertType: 'alert-success', title: 'Sukces', message: 'Plik został usunięty'});
          dispatch('getFiles', file.folder_id)
        } else {
          Event.$emit('showAlert', {alertType: 'alert-danger', title: 'Błąd', message: 'Nie udało się usunąć pliku'})
        }

      });

    return response;
  },

  async getFilesForPhrase({commit}, phrase) {
    const response  = await axios.post('/api/filter/files', {query: phrase})
    commit('setFiles', response.data.files)
  }

};

// mutations
const mutations = {

  setFolders(state, folders) {
    state.folders = {
      name: 'Folder główny',
      folderId: 0,
      id: 0,
      children: folders
    }
  },

  setFiles(state, files) {
    state.files = files;
  },

  setEditFile(state, file) {
    state.editFile = file;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
