<template>
  <div class="delivery-checker__body">

    <label for="delivery-checker-zip">Wpisz kod pocztowy</label>

    <input
      v-model="zip"
      type="text"
      class="delivery-checker__zip"
      id="delivery-checker-zip"
      @keyup="maskZip"
      autofocus
    >

    <div class="delivery-checker__result">
      <p v-if="checking">Sprawdzamy...</p>
      <template v-if="result === DELIVERABLE">
        <p><i class="fa fa-check-circle-o"></i> Dowozimy do Twojej lokalizacji</p>
        <p><small>Koszt dostawy: <b>{{ deliveryPrice }} zł</b> | Darmowa dostawa od: <b>{{ freeDeliveryFrom }} zł</b></small></p>
      </template>
      <p v-if="result === UNDELIVERABLE">
        <i class="fa fa-times-circle-o"></i> Niestety, nie dowozimy do Twojej lokalizacji
      </p>
    </div>

  </div>
</template>

<script>
import AvailabilityService from "../../services/AvailabilityService";
import Helpers from "../../mixins/Helpers";

const DELIVERABLE = 1
const UNDELIVERABLE = 2

export default {
  name: "DeliveryCheckerBody",

  mixins: [Helpers],

  data() {
    return {
      result: 0,
      zip: '',
      deliveryPrice: 0,
      freeDeliveryFrom: 0,
      checking: false,
      states: ['neutral', 'success', 'error'],
      DELIVERABLE,
      UNDELIVERABLE,
    }
  },

  methods: {
    maskZip() {
      this.zip = this.formatZip(this.zip)
      if (this.zip.length > 5)
        this.checkZip()
      else
        this.result = 0
    },

    async checkZip() {
      this.checking = true
      const {data} = await AvailabilityService.checkZipCode(this.zip)
      this.result = data.status === 'success' ? DELIVERABLE : UNDELIVERABLE;
      this.deliveryPrice = data.deliveryPrice
      this.freeDeliveryFrom = data.freeDeliveryFrom

      this.checking = false
      this.$emit('resultChanged', this.result)
    }
  }
}
</script>

<style scoped>

</style>
