<template>
  <div
    class="delivery-checker"
    :class="[{ 'delivery-checker--expanded': expanded }, state]"
    v-if="showBadge"
  >
    <div class="delivery-checker__close-badge">
      <button
        class="delivery-checker__close-badge-button"
        @click="hideBadge"
        aria-label="Close"
      >
        <i class="fa fa-times"></i>
      </button>
    </div>
    <div @click="expand">
      <div class="delivery-checker__close">
        <button class="delivery-checker__close-button" @click.stop="close">
          <i class="fa fa-times"></i>
        </button>
      </div>
      <div class="delivery-checker__icon">
        <img
          src="/images/icons/delivery.svg"
          alt="Ikona dowozu"
          title="Dostawa"
        />
      </div>
      <div class="delivery-checker__text">
        <span>Sprawdź czy do Ciebie dowozimy</span>
      </div>

      <delivery-checker-body
        @resultChanged="changeResult"
      ></delivery-checker-body>
    </div>
  </div>
</template>

<script>
import DeliveryCheckerBody from "./DeliveryCheckerBody";

export default {
  name: "DeliveryChecker",
  components: { DeliveryCheckerBody },

  data() {
    return {
      expanded: false,
      result: 0,
      zip: "",
      deliveryPrice: 0,
      freeDeliveryFrom: 0,
      checking: false,
      states: ["neutral", "success", "error"],
      showBadge: true,
    };
  },

  computed: {
    state() {
      return this.states[this.result];
    },
  },

  methods: {
    expand() {
      this.expanded = true;
    },

    close() {
      this.expanded = false;
      this.result = 0;
      this.zip = "";
    },

    changeResult(result) {
      this.result = result;
    },

    hideBadge() {
      this.showBadge = false;
    },
  },
};
</script>

<style scoped></style>
