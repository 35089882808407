<template>
  <div class="mini-cart__product">
    <div class="mini-cart__btn--delete" @click="remove">
      <div v-if="!removing" class="fa fa-trash"></div>
      <div v-else class="fa fa-spinner fa-spin"></div>
    </div>
    <a :href="product.product.link.url" class="mini-cart__product--link">
      <div class="mini-cart__product--image">
        <img
          class="mini-cart__product--img"
          v-if="product.product.file"
          :src="'/uploads/' + product.product.file.filename"
          :alt="'Zdjęcie produktu'"
          :title="'Zdjęcie produktu'"
        />
        <i v-else class="fa fa-camera"></i>
      </div>
      <div class="mini-cart__product--name" v-text="product.product.name"></div>
      <div class="mini-cart__product--amount">
        <template v-if="!isAmountHidden"> {{ product.amount }} szt.</template>
      </div>
      <div class="mini-cart__product--price">{{ totalPrice }} zł</div>
    </a>
  </div>
</template>

<script>
export default {
  name: "MiniCartProduct",

  props: ["product"],

  data() {
    return {
      removing: false,
    };
  },

  computed: {
    isAmountHidden() {
      return (
        this.product.product.product_type_id === 2 &&
        parseFloat(this.product.product.price) === 0
      );
    },

    totalPrice() {
      return (
        +this.product.price * this.product.amount + +this.product.extra_price
      );
    },
  },

  methods: {
    async remove() {
      this.removing = true;
      await this.$store.dispatch("cart/removeProduct", this.product);
      this.removing = false;
    },
  },
};
</script>

<style scoped></style>
