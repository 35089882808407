import ApiService from "./ApiService";

export default {
  getBlockades(data) {
    return ApiService.post("/api/blockades", data);
  },

  getProductAvailabilities(categoryId, data) {
    return ApiService.post(`/api/product/availabilities/${categoryId}`, data);
  },

  getFirstAvailableDateForZipCode(payload) {
    return ApiService.post(`/api/product/availabilities/for-area`, payload);
  },
};
