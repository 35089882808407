import Vue from "vue";
import Vuex from "vuex";
import cart from "./modules/cart";
import products from "./modules/products";
import files from "./modules/files";
import extras from "./modules/extras";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    cart,
    products,
    files,
    extras,
  },
});
