import CartDetailsService from "../../services/CartDetailsService";
import ProductService from "../../services/ProductService";

const state = {
  sending: false,
  available: false,
  details: {
    zip: "",
    date: "",
    hour: "",
    area: "",
    delivery_price: 25,
    extra_price: 0,
    logistic_minimum: 500,
    first_possible_date: null,
    max_possible_date: null,
    express: false,
    express_multiplier: 1,
    promotionForOnlinePayment: 0,
    advancedPaymentPercentage: 0.3,
    openingHours: [],
  },
  valid: {
    zip: false,
    date: false,
    hour: false,
  },
  products: [],
  epergnes: [],
  blockades: [],
  availabilities: [],
};

const getters = {
  isAvailable: (state, getters, rootState) => {
    return (
      state.details.zip !== "" &&
      state.details.date !== "" &&
      state.details.hour !== ""
    );
  },
  isEmpty: (state) => {
    return state.products.length === 0;
  },

  cartExtraPrice: (state) => {
    if (state.products.length === 0) {
      return 0;
    }
    let sum = 0;

    for (let product of state.products) {
      sum += +product.extra_price;
    }

    return sum;
  },

  cartTotalPrice: (state) => {
    if (state.products.length === 0) return 0;

    let sum = 0;
    for (let product of state.products) {
      sum += +product.price * +product.amount + +product.extra_price;
    }

    return sum;
  },

  expressPrice: (state, getters) => {
    return getters.cartTotalPrice * (state.details.express_multiplier - 1);
  },

  isBlocked: (state) => (type, id) => {
    return (
      state.blockades.filter(
        (blockade) => blockade.type_name === type && blockade.type_id === id
      ).length > 0
    );
  },
};

// actions
const actions = {
  fetchEpergnes({ state }) {
    axios.get("/koszyk/patery").then((response) => {
      state.epergnes = response.data;
    });
  },

  fetchProducts({ state, commit, dispatch }) {
    state.sending = true;
    let request = axios.get("/koszyk/wszystkie-produkty");

    request.then((response) => {
      state.products = response.data;
      state.sending = false;
      dispatch("refreshDetails");
    });

    return request;
  },

  addProduct(
    { state, commit, dispatch },
    { productId, amount, productDetails }
  ) {
    if (
      state.blockades.filter((blockade) => blockade.product_id === productId)
        .length > 0
    ) {
      return;
    }
    state.sending = true;
    const data = {
      amount: amount,
      product_id: productId,
      product_details: productDetails,
    };
    let request = axios.post("/koszyk/dodaj-produkt", data);

    request.then((response) => {
      state.sending = false;
      dispatch("fetchProducts");
    });

    return request;
  },

  removeProduct({ state, dispatch }, cartProduct) {
    state.sending = true;
    let request = axios.post("/koszyk/usun-produkt", {
      productId: cartProduct.id,
    });

    request.then((response) => {
      state.sending = false;
      state.products = response.data;
      dispatch("refreshDetails");
    });

    return request;
  },

  removeAllProducts({ state, dispatch }) {
    state.sending = true;
    let request = axios.get("/koszyk/oproznij");

    request.then((response) => {
      state.sending = false;
      state.products = response.data;
      dispatch("refreshDetails");
    });

    return request;
  },

  updateProductAmount({ state, commit, dispatch }, data) {
    state.sending = true;

    let request = axios.post("/koszyk/aktualizuj-produkt", {
      productId: data.productId,
      amount: data.amount,
    });

    request.then((response) => {
      state.sending = false;
      state.products = response.data;
      dispatch("refreshDetails");
    });

    return request;
  },

  refreshDetails({ state, commit }) {
    let promise = axios.get("/koszyk/dane-dostawy");
    promise.then((response) => {
      commit("setDetails", { details: response.data });
    });

    return promise;
  },

  updateDetails({ state, commit }, details) {
    let request = axios.post("/aktualizuj-dane-dostawy", details.details);
    request.then((response) => {
      if (response.data.status === "success") {
        // commit('setDetails', details)
        commit("SET_DETAILS", response.data.cart);
      }
    });

    return request;
  },

  setZip({ state, commit }, zip) {
    const response = CartDetailsService.setZip(zip);
    response.then((response) => {
      console.log('response', response);
      if (response.data.status === "success") {
        commit("SET_ZIP", zip);
        state.details.first_possible_date = response.data.first_possible_date;
      } else commit("SET_ZIP", "");
    });

    return response;
  },

  setDate({ state, commit, dispatch }, date) {
    const response = CartDetailsService.setDate(date);
    response.then((response) => {
      if (response.data.status === "success") {
        commit("SET_DATE", date);
        dispatch("getBlockades", { date: date.date, zip: state.details.zip });
      } else commit("SET_DATE", "");
    });

    return response;
  },

  setHour({ commit }, hour) {
    const response = CartDetailsService.setHour(hour);
    response.then((response) => {
      if (response.data.status === "success") {
        commit("setAvailable", true);
        commit("SET_HOUR", hour);
      } else {
        commit("setAvailable", false);
        // commit('SET_HOUR', '');
      }
    });

    return response;
  },

  setDetails({ commit }, details) {
    const response = CartDetailsService.setCartDetails(details);

    response.then((response) => {
      if (response.data.status === "success") {
        commit("SET_DETAILS", response.data.cart);
      }
    });
  },

  getBlockedDays({ commit }, details) {
    return CartDetailsService.getBlockedDays(details);
  },

  getHourInformation({ commit }, details) {
    return CartDetailsService.getHourInformation(details);
  },

  getBlockades({ commit }, data) {
    ProductService.getBlockades(data).then((response) => {
      commit("SET_BLOCKADES", response.data.blockades);
    });
  },

  getProductAvailabilities({ commit }, categoryId, data) {
    ProductService.getProductAvailabilities(categoryId, data).then(
      (response) => {
        commit("SET_AVAILABILITIES", response.data.availabilities);
      }
    );
  },
};

// mutations
const mutations = {
  SET_ZIP(state, { zip }) {
    state.details.zip = zip;
  },
  SET_DATE(state, { date }) {
    state.details.date = date;
  },
  SET_HOUR(state, { hour }) {
    state.details.hour = hour;
  },

  SET_DETAILS(state, details) {
    state.details.zip = details.zip;
    state.details.date = details.date;
    state.details.hour = details.hour;
    state.details.area = details.area.id;
    state.details.logistic_minimum = details.logistic_minimum;
    state.details.delivery_price = details.delivery_price;
    state.details.extra_price = details.extra_price;
    state.details.first_possible_date = details.first_possible_date;
    state.details.max_possible_date = details.max_possible_date;
    state.details.express = details.express;
    state.details.express_multiplier = details.express_multiplier;
    state.details.promotionForOnlinePayment =
      details.promotion_for_online_payment;
    state.details.advancedPaymentPercentage =
      details.advanced_payment_percentage;
  },

  RESET_DATE(state) {
    state.details.date = "";
  },
  RESET_HOUR(state) {
    state.details.hour = "";
  },
  setAvailable(state, available) {
    state.available = available;
  },

  setDetails(state, { details }) {
    state.details.zip = details.zip;
    state.details.date = details.date;
    state.details.hour = details.hour;
    state.details.area = details.area.id;
    state.details.first_possible_date = details.first_possible_date;
    state.details.max_possible_date = details.max_possible_date;
    state.details.extra_price = details.extra_price;
    state.details.express = details.express;
    state.details.express_multiplier = details.express_multiplier;
    state.details.promotionForOnlinePayment =
      details.promotion_for_online_payment;
    state.details.advancedPaymentPercentage =
      details.advanced_payment_percentage;

    if (details.delivery_price !== undefined)
      state.details.delivery_price = details.delivery_price;
    if (details.logistic_minimum !== undefined)
      state.details.logistic_minimum = details.logistic_minimum;
  },

  SET_BLOCKADES(state, blockades) {
    state.blockades = blockades;
  },

  SET_AVAILABILITIES(state, availabilities) {
    state.availabilities = availabilities;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
