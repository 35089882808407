<template>
  <div class="product-availability__first-date">
    <small  v-if="zip">Najwcześniejszy możliwy termin zamówienia: <b>{{ firstPossibleDate }}</b></small>
    <small v-else>Wpisz kod pocztowy, aby poznać najwcześniejszy możliwy termin zamówienia.</small>
  </div>
</template>

<script>
import {mapState} from "vuex";
import ProductService from "../../services/ProductService";

export default {
  name: "ProductAvailabilityFirstDate",
  props: {
    availableFrom: {
      type: String
    },
    productId: {
      type: Number
    }
  },

  data() {
    return {
      date: null
    }
  },

  computed: {
    firstPossibleDate() {
      return this.date ? this.date : this.availableFrom
    },
    ...mapState({
      zip: state => state.cart.details.zip
    })
  },

  watch: {
    zip() {
      this.date = null
      this.fetchFirstPossibleDate(this.zip, this.productId)
    }
  },

  methods: {
    async fetchFirstPossibleDate(zip, productId) {
      const response = await ProductService.getFirstAvailableDateForZipCode({zip, productId})
      this.date = response.data.firstPossibleDate
    }
  }
}
</script>

<style scoped>

</style>
