export default class FolderTree {
  constructor() {

  }
  getTree(id) {
    console.log(id);

  }
  getFiles(folderId) {
    console.log(folderId);

  }
  parse(folder) {
    let children = [];
    for (let index in folder.children) {

      if (folder.children.hasOwnProperty(index)) {
        children.push(this.parse(folder.children[index]));
      }
    }
    folder.children = children;

    return folder;
  }
  changeName(folderId, newName) {

  }
}
