const showImage = (el, binding) => {
  const offset = 300;
  const elementDistance = el.getBoundingClientRect().top;

  if (window.scrollY + window.innerHeight + offset > elementDistance) {
    el.classList.add("lazy-loaded");
    el.style.backgroundImage = `url(${binding.value})`;
  }
};

export default {
  bind: function (el, binding) {
    document.addEventListener("DOMContentLoaded", () => showImage(el, binding));
  },
  inserted: function (el, binding) {
    window.addEventListener("scroll", () => showImage(el, binding));
  },
};
