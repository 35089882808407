<template>
  <div class="row zip-code no-gutters">
    <div class="col-md-4">Wpisz swój kod pocztowy</div>

    <div class="col-md-3"><input v-model="zip" name="zip-code" type="text" id="zip-code" class="zip-code"></div>

    <div class="col-md-4"><input type="submit" value="Sprawdź" id="check-zip-code-btn" class="btn btn-primary" @click="onClick"></div>

    <div class="col-md-12">
      <div :class="['validation-info', status]" id="validation-info" v-text="message"></div>
    </div>
  </div>
</template>

<script>
import CartDetailsService from "../../services/CartDetailsService";

export default {
  name: "PageCheckZipCodeForm",

  data() {
    return {
      zip: '',
      message: '',
      status: ''
    }
  },

  methods: {
    onClick() {
      const response = CartDetailsService.setZip({zip: this.zip}).then(response => {
        this.message = response.data.message
        this.status = response.data.status
      })

    }
  }
}
</script>

<style scoped>
</style>
