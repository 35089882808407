<template>
  <div class="reviews">
    <div
      class="review carousel-item"
      v-for="(review, index) of reviews"
      :class="active === index ? 'active' : ''"
      :key="review.id"
    >
      <blockquote v-html="review.desc"></blockquote>
      <p class="author" v-html="review.author"></p>
    </div>
    <div class="reviews-navigation">
      <div class="reviews-nav-btn prev" @click="prev">poprzednia</div>
      <div class="reviews-nav-btn next" @click="next">następna</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "reviews",

  props: ["reviews"],

  data() {
    return {
      active: 0,
    };
  },

  methods: {
    prev() {
      this.active--;
      if (this.active < 0) {
        this.active = this.reviews.length - 1;
      }
    },

    next() {
      this.active++;
      if (this.active > this.reviews.length - 1) {
        this.active = 0;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.reviews {
  width: 100%;
  max-width: 300px;
  margin: 0 auto;

  .review {
    display: none;

    blockquote {
      margin: 0;
    }

    &.active {
      display: block;
    }

    .author {
      color: #fff;
    }
  }

  .reviews-navigation {
    display: flex;
    justify-content: center;
    max-width: 300px;
    width: 100%;
    padding: 20px 0;
  }

  .reviews-nav-btn {
    cursor: pointer;
    padding: 8px;

    &:hover {
      color: #eee;
    }
  }
}
</style>
