import ExtrasService from "../../services/ExtrasService";
import ExtraGroupService from "../../services/ExtraGroupService";

const state = {
  extras: [],
  groups: [],
}

const getters  = {

}

const actions = {
  getExtrasForZip({commit}, zip) {
    ExtrasService.getForZip(zip).then(response => {
      commit('SET_EXTRAS', response.data.extras);
    })
  },

  getGroupsForZip({commit}, zip) {
    ExtraGroupService.getForZip(zip).then(response => {
      commit('SET_GROUPS', response.data.groups)
    })
  }
}

const mutations = {
  SET_EXTRAS(state, extras) {
    state.extras = extras;
  },

  SET_GROUPS(state, groups) {
    state.groups = groups;
  },

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
