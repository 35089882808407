<template>
    <div class="product-image" @click="zoom = !zoom" :class="{'product-image__show-full-size':zoom}"  :title="name">

        <img class="product-image__thumbnail" itemprop="image" :src="thumbnailSrc" :alt="name" :title="name">

        <div class="product-image-zoom" v-if="zoom">
            <div class="product-image-zoom__container">
                <img class="product-image-zoom__img" itemprop="image" :src="image" :alt="name" :title="name">
            </div>
        </div>

    </div>
</template>

<script>
export default {
    name: "ProductImage",
    props: ['image', 'thumbnail', 'name'],

    data() {
        return {
            zoom: false,
        }
    },

    computed: {
        thumbnailSrc() {
            if(this.thumbnail)
                return this.thumbnail

            return this.image
        }
    }
}
</script>

<style lang="scss" scoped>
.product-image {
    position: relative;
    cursor: pointer;

    &::after {
        content: '';
        position: absolute;
        right: 0;
        left: 0;
        top: 0;
        bottom: 0;
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
        background: rgba(255,255,255, .5) url('/images/icons/zoom-image.svg') no-repeat center center / 100px 100px;
    }

    &:hover {
        &::after {
            opacity: 1;
        }
    }
}

.product-image__thumbnail {

}

.product-image-zoom {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1500;
    background-color: rgba(0, 0, 0, .8);
    display: flex;
    justify-content: center;
    align-content: center;
}

.product-image-zoom__container {
    text-align: center;
}

.product-image-zoom__img {
    margin-top: 50vh;
    transform: translateY(-50%);
    max-width: 100%;
    max-height: 100%;
    opacity: 0;
}

.product-image__show-full-size {
    .product-image-zoom {
        animation: showBackground 100ms ease-in-out;

        img {
            animation: showImage 500ms ease-in-out;
            animation-fill-mode: forwards;
            animation-delay: .3s;
        }
    }

}

@keyframes showBackground {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes showImage {
    from {
        opacity: 0;
        //transform: scale(1) translateY(-50%);
    }
    to {
        opacity: 1;
        //transform: scale(1) translateY(-50%);
    }
}
</style>
