export default {
  methods: {
    fireResizeEvent() {
      setTimeout(function () {
        $(window).trigger("resize");
      }, 300);
    },

    priceFormat(value) {
      return parseFloat(value).toFixed(2);
    },

    formatZip(zip) {
      if (!zip) return "";

      zip = zip.replace(/\D+/gi, "").substr(0, 5);
      const part1 = zip.slice(0, 2);
      const part2 = zip.slice(2, 5);

      return part1 + (zip.length > part1.length ? "-" + part2 : "");
    },
  },
};
