import ApiService from "./ApiService";

export default {

  getForZip(zip) {
    return ApiService.post('/api/extras/groups', {zip: zip})
  },

  getForProduct(productId) {
    return ApiService.get(`/api/extras/for-product/${productId}`)
  }
}
