<template>
  <section class="cookie-info" v-if="!accepted">
    <div class="cookie-info__container">
      <div class="cookie-info__content">
        Ta strona internetowa wkorzystuje pliki cookie, by umożliwić korzystanie
        z niektórych funkcji i poprawić doświadczenia użytkownika podczas
        wizyty. Korzystanie z witryny oznacza apceptację sposobu w jaki
        wykorzystujemy pliki cookie.
        <a class="text-secondary" href="/informacja-o-plikach-cookie"
          ><b>Dowiedz się więcej</b></a
        >
      </div>
      <div class="cookie-info__accept">
        <a @click="accept" class="btn btn-primary">AKCEPTUJĘ</a>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "CookieInfo",

  data() {
    return {
      accepted: false,
    };
  },

  mounted() {
    if (document.cookie.search("cookieAccepted=true") !== -1) {
      this.accepted = true;
    }
  },

  methods: {
    accept() {
      this.accepted = true;
      document.cookie = "cookieAccepted=true";
    },
  },
};
</script>

<style lang="scss" scoped>
.cookie-info {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;

  .cookie-info__container {
    display: flex;
    flex-direction: column;
    max-width: 750px;
    padding: 15px;
    background-color: #fff;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);

    .cookie-info__content {
      margin-bottom: 15px;
      font-size: 13px;
    }

    .cookie-info__accept {
      display: flex;
      min-width: 120px;
      align-items: center;

      .btn-primary {
        width: 100%;
        max-height: 35px;
        color: #fff;
        cursor: pointer;
      }
    }
  }
}

@media screen and (min-width: 992px) {
  .cookie-info {
    .cookie-info__container {
      flex-direction: row;

      .cookie-info__content {
        margin-right: 15px;
        margin-bottom: 0;
      }
    }
  }
}
</style>
