export default {
  methods: {
    showInvalidGroupWarning: function () {
      this.warning(
        "Wybierz odpowiednie ilości w zaznaczonych na czerwono grupach",
        "Niepoprawne ilości"
      );
    },

    showInvalidDeliveryDetails() {
      this.warning(
        "Wpisz kod pocztowy miejsca dostawy oraz datę i planowane godziny dostawy.",
        "Dostawa"
      );
    },

    showPricingSentSuccessfully() {
      this.information(
        "Wycena została wysłana. Skontaktujemy się, gdy będziemy mieli gotową wycenę.",
        "Wycena"
      );
    },

    showInvalidExtrasWarning() {
      this.warning("Wybierz odpowiednie dodatki", "Opcje dodatkowe");
    },

    productIsNotAvailableInTheAreaWarning() {
      this.warning(
        "Produkt jest niedostępny w wybranym obszarze",
        "Produkt niedostępny"
      );
    },

    productIsNotAvailableOnDate() {
      this.warning(
        "Produkt jest niedostępny wybranego dnia",
        "Produkt niedostępny"
      );
    },

    warning(text, title) {
      this.$swal({
        type: "warning",
        title: title || "Uwaga",
        text: text,
      });
    },

    information(text, title) {
      this.$swal({
        type: "info",
        title: title || "Informacja",
        text: text,
      });
    },

    expressOrderInformation() {
      this.information(
        "Wybrany dzień i godzina może być obsłużona jedynie w trybie ekspresowym, co oznacza, że wartość zamówienia będzie większa.",
        "Zamówienie ekspresowe"
      );
    },
  },
};
