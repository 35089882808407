import ApiService from "./ApiService";

export default {

    setZip(zip) {
        return ApiService.post('/sprawdz-kod-pocztowy', zip);
    },

    setDate(date) {
        return ApiService.post('/sprawdz-date', date);
    },
    setHour(hour) {
        return ApiService.post('/sprawdz-godzine', hour);
    },

    setCartDetails(details) {
        return ApiService.post('/aktualizuj-dane-dostawy', details);
    },

    getBlockedDays(details) {
        return ApiService.post('/dostepnosc/dni', details);
    },

    getHourInformation(details) {
        return ApiService.post('/dostepnosc/godziny', details);
    }
}
