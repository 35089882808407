<template>
  <div class="dd">
    <span @click.prevent="toggle">
    <slot name="button"></slot>
    </span>
    <div class="dd-content" :class="{'dd-content--show':show}" @click="keepOpen">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "DropDownMenu",
  data() {
    return {
      show: false
    }
  },

  mounted() {
    this.$root.$on('dropdown-opened', (component) => {
      if (component !== this) {
        this.show = false
      }
    })
  },

  methods: {
    toggle(event) {
      this.show = !this.show
      this.$root.$emit('dropdown-opened', this)
      event.stopPropagation()
    },

    keepOpen(event) {
      event.stopPropagation()
    }
  }
}
</script>

<style lang="scss" scoped>
.dd-content {

  //display: none;
}
</style>
