<template>
  <div class="mini-cart__wrapper">
    <div class="mini-cart__trigger" @click="showMinicart">
      <div class="mini-cart__amount rounded-circle">{{ productCount }}</div>
      <i class="mini-cart__icon fa fa-cart-arrow-down"></i>
      <span class="mini-cart__text" href="/koszyk">koszyk</span>
    </div>
    <transition name="slide-down">
      <div class="mini-cart" v-if="show">
        <div class="mini-cart__header">
          <span class="mini-cart_header--text"> ZAWARTOŚĆ <b>KOSZYKA</b></span>
          <button class="mini-cart__close" @click="closeMinicart">
            &times;
          </button>
        </div>

        <div class="mini-cart__body">
          <div class="mini-cart__info mini-cart__preloader" v-if="loading">
            <i class="fa fa-spinner fa-spin" aria-hidden="true"></i> proszę
            czekać...
          </div>

          <div class="mini-cart__info" v-if="isEmpty">
            <i class="fa fa-warning" aria-hidden="true"></i> koszyk jest pusty
          </div>

          <div class="mini-cart__products">
            <mini-cart-product
              :product="product"
              v-for="product of products"
              :key="product.id"
            ></mini-cart-product>
          </div>

          <div class="mini-cart__product">
            Minimum logistyczne: {{ details.logistic_minimum }} zł |
            <b>Wartość koszyka: {{ cartTotalPrice }} zł</b>
          </div>
          <div class="mini-cart__product" v-if="details.express">
            Zamówienie ekspresowe
            <i
              class="fa fa-info express-info-icon"
              @click="expressOrderInformation"
            ></i>
          </div>
        </div>

        <div class="mini-cart__footer">
          <a href="/koszyk" class="btn btn-primary btn-block"> POKAŻ KOSZYK </a>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import Warnings from "../../mixins/Warnings";
import MiniCartProduct from "./MiniCartProduct";

export default {
  name: "MiniCart",
  components: { MiniCartProduct },
  mixins: [Warnings],

  data() {
    return {
      loading: false,
      show: false,
      productsLoaded: false,
    };
  },

  computed: {
    productCount() {
      return this.products.reduce((sum, product) => {
        return sum + product.amount;
      }, 0);
    },

    isEmpty() {
      return this.products.length === 0;
    },

    ...mapState({
      products: (state) => state.cart.products,
      details: (state) => state.cart.details,
    }),

    ...mapGetters("cart", ["cartTotalPrice"]),
  },

  methods: {
    showMinicart() {
      this.show = true;
    },

    closeMinicart() {
      this.show = false;
    },
  },
};
</script>

<style scoped>
.slide-down-enter-active,
.slide-down-leave-active {
  transition: transform 0.2s ease-in-out;
}

.slide-down-enter-to,
.slide-down-leave {
  transform: translateY(0);
}

.slide-down-enter,
.slide-down-leave-to {
  transform: translateY(-100%);
}
</style>
